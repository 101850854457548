.booking-form {
    background: #000;
    padding: 40px;
    border-radius: 15px;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
}

.booking-form h2 {
    font-size: 2.5rem;
    color: #FFF;
    margin-bottom: 30px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.booking-form input,
.booking-form select,
.booking-form button {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #d1d3d4;
    font-size: 1.2rem;
    transition: all 0.3s ease;
}

.booking-form input:focus,
.booking-form select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.booking-form button {
    background-color: #007bff;
    color: white;
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.booking-form button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
}

.form-feedback {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 1.1rem;
}

.form-feedback.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.form-feedback.success {
    background-color: #000;
   
}