.footer {
    background-color: #111;
    color: #E0E0E0;
    text-align: center;
    padding: 20px;
    border-top: 1px solid #000;
}

.footer a {
    color: #1E88E5;
    text-decoration: none;
    margin: 0 15px;
    font-size: 20px;
}

.footer a:hover {
    color: #FFF;
}
