

.navbar {
    width: 97%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
}

.navbar-logo a {
    font-size: 1.8rem;
    color: #fff;
    font-weight: bold;
    text-decoration: none;
}

.navbar-links {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    position: relative;
}

.navbar-links a {
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
    padding: 8px 12px; /* Reduced padding */
    transition: color 0.3s ease;
    border-radius: 5px;
}

.navbar-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #00bfff;
    transition: width 0.3s ease;
    position: absolute;
    left: 0;
    bottom: 0;
}

.navbar-links a:hover::after {
    width: 100%;
}

.navbar-links a:hover {
    color: #00bfff;
}

.navbar-links a.active {
    color: #00bfff;
}

@media (max-width: 768px) {
    .navbar-links {
        flex-direction: column;
        gap: 10px;
    }
}
