.room-card {
    background-color: #000;
    color: #E0E0E0;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #000;
    transition: transform 0.3s, box-shadow 0.3s;
    font-family: 'Roboto', sans-serif;
}

.room-card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.room-card img {
    width: 100%;
    border-radius: 12px;
}

.room-card h3 {
    margin: 15px 0;
    font-size: 22px;
    color: #222;
    font-family: 'Poppins', sans-serif;
}

.room-card p {
    font-size: 16px;
    color: #9E9E9E;
}
